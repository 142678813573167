<template>
  <div class="edit-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.meetingName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/schedule/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } }">会议日程管理</el-breadcrumb-item>
      <el-breadcrumb-item>修改会议日程</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="editForm" :rules="editRules" ref="editRef" class="edit-form" label-position="left" label-width="100px">
        <el-form-item label="排序" prop="sort">
          <el-input-number size="medium" v-model="editForm.sort" :min="0" :step-strictly="true"></el-input-number>
        </el-form-item>
        <el-form-item label="日程标题" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="日程时间" prop="time">
          <el-input v-model="editForm.time"></el-input>
        </el-form-item>
        <el-form-item label="日程地址" prop="address">
          <el-input v-model="editForm.address"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="marks">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入备注内容" v-model="editForm.marks"></el-input>
        </el-form-item>
        <el-form-item label="日程内容模式" prop="type">
          <el-radio-group v-model="editForm.type">
            <el-radio :label="1">文字模式</el-radio>
            <el-radio :label="2">图片模式</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="form-item-panle" v-if="editForm.type === 1">
          <el-form-item v-for="(rccontent, index) in editForm.content" :label="'日程内容' + (index + 1)" :key="rccontent.key" :prop="'content.' + index + '.value'">
            <el-row :gutter="20">
              <el-col :span="4">
                <div class="title">时间:</div>
                <el-input label="时间" v-model="rccontent.time"></el-input>
              </el-col>
              <el-col :span="8">
                <div class="title">内容:</div>
                <el-input label="内容" v-model="rccontent.content"></el-input>
              </el-col>
              <el-col :span="9">
                <div class="title">备注:</div>
                <el-input label="备注" v-model="rccontent.marks"></el-input>
              </el-col>
              <el-col :span="3">
                <div class="title">操作</div>
                <el-button type="danger" @click.prevent="removeContent(rccontent)">删除</el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-row style="margin-top: 40px;text-align: center">
            <el-button @click="addContent" type="warning">新增日程</el-button>
          </el-row>
        </div>
        <div class="form-item-panle" v-if="editForm.type === 2">
          <el-form-item label="日程内容图片" prop="content_img">
            <upload :url="editForm.content_img" :filed="'content_img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
          </el-form-item>
        </div>
        <el-form-item label="是否需要权限" prop="is_auth">
          <el-radio-group v-model="editForm.is_auth">
            <el-radio :label="1">需要</el-radio>
            <el-radio :label="2">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancleForm">取消添加</el-button>
          <el-button type="primary" @click="submitForm('editRef')">确认修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../../../../common/Upload'
export default {
  name: 'Edit',
  components: {
    Upload
  },
  data () {
    return {
      editForm: {
        mId: this.$route.params.mId,
        sort: 0,
        title: '',
        time: '',
        address: '',
        marks: '',
        type: 1,
        content: [{
          time: '',
          content: '',
          marks: ''
        }],
        content_img: '',
        is_auth: 2
      },
      editRules: {
        title: [
          { required: true, message: '请填写会议日程标题', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取被修改信息
    async getInfo () {
      const { data: res } = await this.$http.get('/shell-schedule/' + this.$route.params.id)
      if (res.status === 200) {
        this.editForm.sort = res.data.sort
        this.editForm.title = res.data.title
        this.editForm.time = res.data.time
        this.editForm.address = res.data.address
        this.editForm.marks = res.data.marks
        this.editForm.type = res.data.type
        this.editForm.content = res.data.content
        this.editForm.content_img = res.data.content_img
        this.editForm.is_auth = Number(res.data.is_auth)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.editForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editForm[field].splice(checkedIndex, 1)
      }
    },
    // 添加表单
    submitForm () {
      this.$refs.editRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.put('/shell-schedule/' + this.$route.params.id, this.editForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.editRef.resetFields()
            await this.$router.push({ path: '/admin/apps/shell/content/schedule/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    // 取消添加
    cancleForm () {
      this.$router.push({ path: '/admin/apps/shell/content/schedule/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
    },
    removeContent (item) {
      const index = this.editForm.content.indexOf(item)
      if (index !== -1) {
        this.editForm.content.splice(index, 1)
      }
    },
    addContent () {
      this.editForm.content.push({
        value: '',
        key: Date.now()
      })
    }
  }
}
</script>

<style scoped>
.edit-form{
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.form-item-panle /deep/.el-form-item__content{
  background-color: #FFFFFF;
  padding: 10px;
}
</style>
